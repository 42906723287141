import React, { useState, useRef, useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const HeroSection = styled('section')`
  margin-top: 60px;
  padding: 10px 0;
  text-align: left;
`;
const HeroText = styled(Typography)`
  margin-bottom: 5px;
`;
const HeroButton = styled(Button)`
  margin-top: 20px;
`;
const FeatureSection = styled('section')`
  padding: 10px 0;
  text-align: left;
`;
const FeatureText = styled(Typography)`
  margin-bottom: 5px;
`;

const LandingPage = () => {

    return (
        <div style={{ color: 'rgb(45,45,65)', marginBottom: '20px', padding: 'auto' }}>
            <HeroSection style={{ marginTop: '20px' }}>
                <Container maxWidth="md">
                    <HeroText variant="h4" component="h1" style={{ fontSize: 'calc(40px + 0.4vw)', fontWeight: 1000, fontFamily: 'Gill Sans, sans-serif', lineHeight: '40px' }}>
                        WELCOME TO <br />GSTORE <br />F-EXCHANGE
                    </HeroText>
                    <Typography variant="h5">
                        The Better Way To Exchange Fiat & Crypto
                    </Typography>
                    {/* <HeroButton variant="contained" color="primary">
                        Get Started
                    </HeroButton> */}
                </Container>
            </HeroSection>

            <FeatureSection >
                <Container maxWidth="md">
                    <FeatureText variant="h4" component="h2" style={{ fontWeight: 700, lineHeight: '30px' }}>
                        WHY CHOOSE<br /> GSTORE<br /> F-EXCHANGE ?
                    </FeatureText>
                    <Typography variant="h5">
                        - Low Fees
                        <br />
                        - Can Negotiate Rate
                        <br />
                        - Fast Transaction
                    </Typography>
                </Container>
            </FeatureSection>
        </div >
    );
}

export default LandingPage;
