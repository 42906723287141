import React, { useState, useRef, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Button, Autocomplete, Box, Divider, Link } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOffOutlined';
import StreamIcon from '@mui/icons-material/StreamOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

const ExchangeForm = () => {
    const isChangeCustomerSend = useRef(true);
    // const elementToCaptureRef = useRef();

    const dropdownImageSize = "calc(20px)";
    const [customerSend, setCustomerSend] = useState("");
    const [customerSendRawValue, setCustomerSendRawValue] = useState("");
    const [customerGet, setCustomerGet] = useState("");
    const [customerGetRawValue, setCustomerGetRawValue] = useState("");
    const [transactionFee, setTransactionFee] = useState("2")
    const [transactionFeeRawValue, setTransactionFeeRawValue] = useState("2")
    const [exchangeRate, setExchangeRate] = useState("")
    const [exchangeRateRawValue, setExchangeRateRawValue] = useState("")

    const [isMenuVisible, setMenuVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState('USD');

    const [isMenuVisible2, setMenuVisible2] = useState(false);
    const [selectedItem2, setSelectedItem2] = useState('USD');
    const [exchangeRateList, setExchangeRateList] = useState({})

    const items = [
        { label: 'IDR', logo: <img src={require('../Currency/IDR.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'USD', logo: <img src={require('../Currency/USD.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'AUD', logo: <img src={require('../Currency/AUD.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'SGD', logo: <img src={require('../Currency/SGD.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'GBP', logo: <img src={require('../Currency/GBP.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'EUR', logo: <img src={require('../Currency/EURO.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'USDT', logo: <img src={require('../Currency/USDT.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'USDC', logo: <img src={require('../Currency/USDC.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'BUSD', logo: <img src={require('../Currency/BUSD.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> },
        { label: 'TUSD', logo: <img src={require('../Currency/TUSD.png')} style={{ width: dropdownImageSize, height: dropdownImageSize, marginRight: '5px' }} /> }
        // Add more items with logos here
    ];

    useEffect(() => {
        getGlobalExchangeRate();
    }, []);
    useEffect(() => {
        if (!isChangeCustomerSend.current) return;
        updateCustomerGet()
    }, [customerSendRawValue, transactionFeeRawValue, exchangeRateRawValue, selectedItem, selectedItem2, exchangeRateList])
    useEffect(() => {
        if (isChangeCustomerSend.current) return;
        updateCustomerSend()
    }, [customerGetRawValue, transactionFeeRawValue, exchangeRateRawValue, selectedItem, selectedItem2, exchangeRateList])
    const getGlobalExchangeRate = () => {
        // Define the API endpoint
        const apiUrl = 'https://gstorefexchanger.com/logic_server/gstoreWeb/publicCurrency';

        // Perform the GET request using fetch
        fetch(apiUrl)
            .then((response) => response.json())
            .then((responseData) => {
                const listCurrency = items.map((item) => item.label)
                const newExchange = {}
                const listCurrencyRateBase = {}
                Object.entries(responseData.data.currencyList.rates).filter((val, idx, arr) => listCurrency.includes(val[0])).forEach((val) => {
                    listCurrencyRateBase[val[0]] = val[1]
                })

                listCurrency.forEach((val1) => {
                    let isCrypto1 = false
                    let mainCurrencyValue = listCurrencyRateBase[val1]
                    if (!mainCurrencyValue && val1.includes("USD")) {
                        mainCurrencyValue = listCurrencyRateBase["USD"]
                        if (val1.length > 3) isCrypto1 = true;
                    }

                    listCurrency.forEach((val2) => {
                        let isCrypto2 = false
                        let secondaryCurrencyValue = listCurrencyRateBase[val2]
                        if (!secondaryCurrencyValue && val2.includes("USD")) {
                            secondaryCurrencyValue = listCurrencyRateBase["USD"]
                            if (val2.length > 3) isCrypto2 = true;
                        }

                        const newExchangeName = val1 + val2
                        let newExchangeRate = val1 == "IDR" ? mainCurrencyValue / secondaryCurrencyValue : secondaryCurrencyValue / mainCurrencyValue
                        if (isCrypto1 && isCrypto2) newExchangeRate -= 0.0005;
                        Object.assign(newExchange, { [newExchangeName]: newExchangeRate })
                    })
                })
                console.log(newExchange)
                setExchangeRateList(newExchange);
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
    }
    const formatCurrency = (value, textRawValue, noDecimal = false) => {
        // Format the input value as a currency string (e.g., $1,234.56)
        // if (value.toString().match(/./g) != null && value.match(/./g).length == 1) isDecimal = true;
        const rawValue = textRawValue.toString()
        const foundMatch = rawValue.match(/\.0/g)
        if (!noDecimal) {
            let stringFormatValue = value.toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: foundMatch && foundMatch.length == 1 ? 1 : 0
            });
            if (rawValue.charAt(rawValue.length - 1) == '.') stringFormatValue += "."
            return stringFormatValue
        }
        else {
            let stringFormatValue = value.toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0
            });
            if (rawValue.charAt(rawValue.length - 1) == '.') stringFormatValue += "."
            return stringFormatValue
        }
    };
    const customerCostChangeText = (text) => {
        // Remove non-numeric characters from the input
        isChangeCustomerSend.current = true;
        const foundMatch = text.match(/\./g)
        if (foundMatch && foundMatch.length > 1) return;

        const numericValue = parseFloat(text.replace(/[^0-9.]/g, ''));
        const rawValue = text.replace(/[^0-9.]/g, '');
        setCustomerSendRawValue(rawValue)
        if (isNaN(numericValue)) {
            setCustomerSend("");
            setCustomerSendRawValue("");
            setCustomerGet("")
            setCustomerGetRawValue("")
            return;
        }
        // Update the formatted value and call the onChange function with the numeric value
        setCustomerSend(formatCurrency(numericValue, rawValue, selectedItem == "IDR"));
    };
    const exchangerCostChangeText = (text) => {
        // Remove non-numeric characters from the input
        isChangeCustomerSend.current = false;
        const foundMatch = text.match(/\./g)
        if (foundMatch && foundMatch.length > 1) return;

        const numericValue = parseFloat(text.replace(/[^0-9.]/g, ''));
        const rawValue = text.replace(/[^0-9.]/g, '');
        setCustomerGetRawValue(rawValue)
        if (isNaN(numericValue)) {
            setCustomerGet("");
            setCustomerGetRawValue("")
            return;
        }
        // Update the formatted value and call the onChange function with the numeric value
        setCustomerGet(formatCurrency(numericValue, rawValue, selectedItem2 == "IDR"));
    };
    const transactionChangeText = (text) => {
        // Remove non-numeric characters from the input
        const foundMatch = text.match(/\./g)
        if (foundMatch && foundMatch.length > 1) return;

        const numericValue = parseFloat(text.replace(/[^0-9.]/g, ''));
        const rawValue = text.replace(/[^0-9.]/g, '');
        setTransactionFeeRawValue(rawValue)
        if (isNaN(numericValue)) {
            setTransactionFee("");
            setTransactionFeeRawValue("");
            return;
        }
        // Update the formatted value and call the onChange function with the numeric value
        setTransactionFee(formatCurrency(numericValue, rawValue));
    }
    const getTransactionFee = () => {
        if (transactionFeeRawValue && customerSendRawValue) {
            const transactionFeePercent = transactionFeeRawValue / 100;
            const transactionFeeNominal = transactionFeePercent * customerSendRawValue;
            return `${transactionFeeNominal.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2 })} ${selectedItem}`
        }
        return "0"
    }
    const getTotalAmountAfterFee = () => {
        if (transactionFeeRawValue && customerSendRawValue) {
            const transactionFeePercent = transactionFeeRawValue / 100;
            const transactionFeeNominal = transactionFeePercent * customerSendRawValue;
            const totalAmountAfterFee = customerSendRawValue - transactionFeeNominal;
            return `${totalAmountAfterFee.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2 })} ${selectedItem}`
        }
        return "0"
    }
    const getExchangeRatePair = () => {
        const exchangeName = selectedItem + selectedItem2;
        if (!exchangeRate && !exchangeRateList[exchangeName]) return ""
        if (exchangeRateList[exchangeName] == 0.9995) return exchangeRateList[exchangeName].toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 4 })
        return exchangeRateList[exchangeName].toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2 })
    }
    const updateCustomerGet = () => {
        if (customerSendRawValue && transactionFeeRawValue && (exchangeRateRawValue || exchangeRateList[selectedItem + selectedItem2])) {
            const transactionFeePercent = transactionFeeRawValue / 100;
            const transactionFeeNominal = transactionFeePercent * customerSendRawValue;
            const totalAmountAfterFee = customerSendRawValue - transactionFeeNominal;
            // console.log(totalAmountAfterFee)
            if (exchangeRate) {
                const customerGetValue = selectedItem == "IDR" ? totalAmountAfterFee / exchangeRateRawValue : totalAmountAfterFee * exchangeRateRawValue
                // console.log(exchangeRateRawValue + " : " + customerGetValue)
                setCustomerGet(formatCurrency(customerGetValue, customerGetValue, selectedItem2 == "IDR"))
                setCustomerGetRawValue(customerGetValue)
            }
            else if (exchangeRateList && exchangeRateList[selectedItem + selectedItem2]) {
                const exchangeRatePair = exchangeRateList[selectedItem + selectedItem2]
                const customerGetValue = selectedItem == "IDR" ? totalAmountAfterFee / exchangeRatePair : totalAmountAfterFee * exchangeRatePair
                // console.log(exchangeRatePair + " : " + customerGetValue)
                setCustomerGet(formatCurrency(customerGetValue, customerGetValue, selectedItem2 == "IDR"))
                setCustomerGetRawValue(customerGetValue)
            }
        }
        else {
            setCustomerGet("")
            setCustomerGetRawValue("")
        }
    }
    const updateCustomerSend = () => {
        if (customerGetRawValue && transactionFeeRawValue && (exchangeRateRawValue || exchangeRateList[selectedItem + selectedItem2])) {
            // console.log(totalAmountAfterFee)
            if (exchangeRate) {
                const totalAmountAfterFee = selectedItem == "IDR" ? customerGetRawValue * exchangeRateRawValue : customerGetRawValue / exchangeRateRawValue
                const customerSendValue = totalAmountAfterFee / (100 - transactionFeeRawValue) * 100
                // console.log(exchangeRateRawValue + " : " + customerSendValue)
                setCustomerSend(formatCurrency(customerSendValue, customerSendValue, selectedItem == "IDR"))
                setCustomerSendRawValue(customerSendValue)
            }
            else if (exchangeRateList && exchangeRateList[selectedItem + selectedItem2]) {
                const exchangeRatePair = exchangeRateList[selectedItem + selectedItem2]
                const totalAmountAfterFee = selectedItem == "IDR" ? customerGetRawValue * exchangeRatePair : customerGetRawValue / exchangeRatePair
                const customerSendValue = totalAmountAfterFee / (100 - transactionFeeRawValue) * 100
                // console.log(exchangeRateRawValue + " : " + customerSendValue)
                setCustomerSend(formatCurrency(customerSendValue, customerSendValue, selectedItem == "IDR"))
                setCustomerSendRawValue(customerSendValue)
            }
        }
        else {
            setCustomerSend("")
            setCustomerSendRawValue("")
        }
    }
    const getChatText = () => {
        let getChatText = "Hello, GStore. I Want To :%0A%0A"
        getChatText += `SEND  : ${selectedItem} ${customerSend}%0A`
        getChatText += `FEE   : ${getTransactionFee()}%0A`
        getChatText += `TOTAL : ${getTotalAmountAfterFee()}%0A`
        getChatText += `RATE  : ${exchangeRate.length > 0 || !exchangeRateList[selectedItem + selectedItem2] ? exchangeRate : getExchangeRatePair()}%0A`
        getChatText += `GET   : ${selectedItem2} ${customerGet}%0A%0A`
        getChatText += `Thank You`
        return getChatText;
    }

    return (
        <div style={{ marginTop: '20px', float: 'left', minWidth: '50%', display: 'block', justifyContent: 'center', alignItems: 'center', marginBottom: '40px' }}>
            <Card style={{ borderRadius: '40px', paddingLeft: 'calc(10px + 1vw)', paddingRight: 'calc(10px + 1VW)', paddingBottom: '20px' }}>
                <CardContent>
                    <Typography variant="caption" style={{ marginBottom: '0px' }}>You Send Exactly</Typography>
                    <div>
                        <TextField
                            label=""
                            variant="outlined"
                            value={customerSend}
                            onChange={(ev) => customerCostChangeText(ev.target.value)}
                            style={{ marginTop: '0px', width: '60%', float: 'left' }}
                            margin="normal"
                        />
                        <Autocomplete
                            style={{ width: '38%', float: 'right' }}
                            autoComplete
                            includeInputInList
                            value={items.find((val) => val.label == selectedItem)}
                            onChange={(ev, newValue) => {
                                setSelectedItem(newValue.label)
                            }}
                            options={items}
                            renderOption={(props, option, state, ownerState) => (
                                <Box
                                    style={{ paddingLeft: '2vw' }}
                                    sx={{
                                        borderRadius: '8px',
                                        // paddingLeft: '0px',
                                        // backgroundColor: 'red',
                                        // paddingRight: '10px',
                                    }}
                                    component="li"
                                    {...props}
                                >
                                    {option.logo}
                                    {ownerState.getOptionLabel(option)}
                                </Box>
                            )}
                            disableClearable
                            renderInput={(params) => (
                                <div>
                                    <div style={{ width: 'calc(20px)', paddingTop: '20px', float: 'left' }}>
                                        {selectedItem ? items.find((val) => val.label == selectedItem).logo : null}
                                    </div>
                                    <TextField fullWidth style={{ width: 'calc(50px + 9vw)', float: 'right' }} {...params} label="Currency" variant="standard" />
                                </div>
                            )}
                        />
                    </div>
                    <div style={{ marginTop: '20px', clear: 'both' }}>
                        <RemoveCircleOutlineIcon style={{ float: 'left', marginRight: '10px' }} />
                        <Typography variant="body1" style={{ marginTop: '0px', width: '55%', float: 'left', height: 'calc(30px)', fontWeight: 'bold' }}>{getTransactionFee()}</Typography>
                        <Typography variant="body1" style={{ textAlign: 'center', float: 'right', padding: 'auto', height: 'calc(30px)', fontWeight: 'bold' }}>2% Fee</Typography>
                    </div>
                    <Divider style={{ marginTop: '10px', marginBottom: '20px', clear: 'both' }} />
                    <div style={{ marginTop: '20px', clear: 'both' }}>
                        <PauseCircleOutlineIcon style={{ float: 'left', marginRight: '10px', transform: `rotate(90deg)` }} />
                        <Typography variant="body1" style={{ marginTop: '0px', width: '55%', float: 'left', height: 'calc(30px)', fontWeight: 'bold' }}>{getTotalAmountAfterFee()}</Typography>
                        <Typography variant="body1" style={{ textAlign: 'center', float: 'right', padding: 'auto', height: 'calc(30px)', fontWeight: 'bold' }}>Total Amount</Typography>
                    </div>
                    <Divider style={{ marginTop: '10px', marginBottom: '20px', clear: 'both' }} />
                    <div style={{ marginTop: '20px', clear: 'both' }}>
                        {selectedItem == "IDR" ?
                            <StreamIcon style={{ float: 'left', marginRight: '10px' }} /> :
                            <HighlightOffIcon style={{ float: 'left', marginRight: '10px' }} />
                        }
                        <Typography variant="body1" style={{ marginTop: '0px', width: '55%', float: 'left', height: 'calc(30px)', fontWeight: 'bold' }}>{exchangeRate.length > 0 || !exchangeRateList[selectedItem + selectedItem2] ? exchangeRate : getExchangeRatePair()}</Typography>
                        <Typography variant="body1" style={{ textAlign: 'center', float: 'right', padding: 'auto', height: 'calc(30px)', fontWeight: 'bold' }}>Rate</Typography>
                    </div>
                    <Divider style={{ marginTop: '10px', marginBottom: '20px', clear: 'both' }} />
                    <Typography style={{ clear: 'both' }} variant="caption" >You Get</Typography>
                    <div style={{ clear: 'both' }}>
                        <TextField
                            label=""
                            variant="outlined"
                            value={customerGet}
                            onChange={(ev) => exchangerCostChangeText(ev.target.value)}
                            style={{ marginTop: '0px', width: '60%', float: 'left' }}
                            margin="normal"
                        />
                        <Autocomplete
                            style={{ width: '38%', float: 'right' }}
                            autoComplete
                            includeInputInList
                            value={items.find((val) => val.label == selectedItem2)}
                            onChange={(ev, newValue) => {
                                setSelectedItem2(newValue.label)
                            }}
                            options={items}
                            renderOption={(props, option, state, ownerState) => (
                                <Box
                                    style={{ paddingLeft: '2vw' }}
                                    sx={{
                                        borderRadius: '8px',
                                        // paddingLeft: '0px',
                                        // backgroundColor: 'red',
                                        // paddingRight: '10px',
                                    }}
                                    component="li"
                                    {...props}
                                >
                                    {option.logo}
                                    {ownerState.getOptionLabel(option)}
                                </Box>
                            )}
                            disableClearable
                            renderInput={(params) => (
                                <div>
                                    <div style={{ width: 'calc(20px)', paddingTop: '20px', float: 'left' }}>
                                        {selectedItem2 ? items.find((val) => val.label == selectedItem2).logo : null}
                                    </div>
                                    <TextField fullWidth style={{ width: 'calc(50px + 9vw)', float: 'right' }} {...params} label="Currency" variant="standard" />
                                </div>
                            )}
                        />
                    </div>
                    <div style={{ width: '100%', clear: 'both', textAlign: 'center' }}>
                        <Link href={'https://api.whatsapp.com/send?phone=+6282164777755&text=' + getChatText()}>
                            <Button variant="contained" style={{ clear: 'both', marginTop: '30px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#25D366' }}>
                                <WhatsAppIcon style={{ marginRight: '5px' }} /> Contact Exchanger
                            </Button>
                        </Link>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ExchangeForm;
