import React, { useState, useRef, useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import testimony4 from '../Images/testimony_4.jpeg';
import testimony5 from '../Images/testimony_5.jpeg';
import testimony6 from '../Images/testimony_6.jpeg';

const HeroSection = styled('section')`
  margin-top: 60px;
  padding: 10px 0;
  text-align: left;
`;
const HeroText = styled(Typography)`
  margin-bottom: 5px;
`;
const HeroButton = styled(Button)`
  margin-top: 20px;
`;
const FeatureSection = styled('section')`
  padding: 10px 0;
  text-align: left;
`;
const FeatureText = styled(Typography)`
  margin-bottom: 5px;
`;

const TestimonySecondary = () => {

    return (
        <div style={{ color: 'rgb(45,45,65)', marginBottom: '20px', padding: 'auto' }}>
            <HeroSection style={{ marginTop: '20px' }}>
                <Container maxWidth="md">
                    <HeroText variant="h4" component="h1" style={{ fontSize: 'calc(40px + 0.4vw)', fontWeight: 1000, fontFamily: 'Gill Sans, sans-serif', lineHeight: '40px' }}>
                        99.99% CUSTOMER SATISFACTION.
                    </HeroText>
                    <Typography variant="h5">
                        Be Safe With US
                    </Typography>
                    {/* <HeroButton variant="contained" color="primary">
                        Get Started
                    </HeroButton> */}
                </Container>
            </HeroSection>

            <FeatureSection >
                <Container maxWidth="md">
                    {/* <FeatureText variant="h4" component="h2" style={{ fontWeight: 700, lineHeight: '30px' }}>
                        ANOTHER WAY<br />TO EXCHANGE MONEY
                    </FeatureText> */}
                    <div style={{ width: '100%', justifyContent: 'flex-start' }}>
                        <img src={testimony4} className='webLogo' style={{ marginRight: '0px', width: '75%', marginBottom: '10px' }} />
                        <img src={testimony5} className='webLogo' style={{ width: '75%', marginBottom: '10px' }} />
                        <img src={testimony6} className='webLogo' style={{ marginLeft: '0px', width: '75%', marginBottom: '10px' }} />
                    </div>
                </Container>
            </FeatureSection>
        </div >
    );
}

export default TestimonySecondary;
