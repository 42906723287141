import logo from './Images/Logo.png';
import './App.css';
import ExchangeForm from './Component/ExchangeForm';
import LandingPage from './Pages/LandingPage';
import Carousel from 'react-material-ui-carousel';
import TrustScore from './Pages/TrustScore';
import TestimonyPrimary from './Pages/Testimony Primary';
import TestimonySecondary from './Pages/Testimony Secondary'
import { Paper, Button, Link, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';

function App() {

  return (
    <div>
      <div style={{ backgroundColor: 'rgba(255,255,255, 0.35)', width: '100%', borderBottomLeftRadius: 50, borderBottomRightRadius: 50 }}>
        <img src={logo} className='webLogo' />
      </div>
      <div className='globalContainer'>
        <div style={{ width: window.innerWidth <= 500 ? "100%" : "50%", float: 'left' }}>
          <Carousel height={450} autoPlay={true} cycleNavigation={true} duration={500} interval={3000}>
            {
              [<LandingPage />, <TrustScore />, <TestimonyPrimary />, <TestimonySecondary />]
            }
          </Carousel>
        </div>
        <ExchangeForm />
      </div>
      <div style={{ backgroundColor: 'rgba(100,100,100, 0.25)', clear: 'both', paddingBottom: '20px', paddingTop: '20px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <Typography variant="h4" style={{ textAlign: 'center', fontFamily: `Garamond, serif` }}>
          CONTACT US
        </Typography>
        <Link href={'https://t.me/gstorefexchanger'} style={{ alignSelf: 'center', marginLeft: '20px', marginRight: '20px' }}>
          <Button variant="contained" style={{ width: 'calc(200px + 5vw)', clear: 'both', marginTop: '15px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#0088CC' }}>
            <TelegramIcon style={{ marginRight: '5px' }} /> @GSTOREFEXCHANGER
          </Button>
        </Link>
        <Link href={"instagram://user?username=GSTOREFEXCHANGER"} style={{ alignSelf: 'center', marginLeft: '20px', marginRight: '20px' }}>
          <Button variant="contained" style={{ width: 'calc(200px + 5vw)', clear: 'both', marginTop: '15px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#C13584' }}>
            <InstagramIcon style={{ marginRight: '5px' }} /> @GSTOREFEXCHANGER
          </Button>
        </Link>
        <Link href={'https://api.whatsapp.com/send?phone=+6282164777755'} style={{ alignSelf: 'center', marginLeft: '20px', marginRight: '20px' }}>
          <Button variant="contained" style={{ width: 'calc(200px + 5vw)', clear: 'both', marginTop: '15px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#25D366' }}>
            <WhatsAppIcon style={{ marginRight: '5px' }} /> +6282164777755
          </Button>
        </Link>
      </div>
      <div style={{ clear: 'both', marginLeft: 'auto', marginRight: 'auto', width: '80%', textAlign: 'center', marginBottom: '20px', paddingTop: '20px' }}>
        Copyright © GStore F-Exchange 2023.
      </div>
    </div >
  );
}

export default App;
